import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from "react-helmet"
import RecipeList from '../components/RecipeList'

export default function recipe() {
    return (
        <Layout>
            {/* For page head */}
            <Helmet>
                <title>Maker Recipe Sdn. Bhd. - Recipe</title>
            </Helmet>

            <RecipeList />
        </Layout>
    )
}